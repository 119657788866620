import { KioskLayout } from "components";
import { useEffect } from "react";
import { trackPageview } from "utils/plausible";

export const KioskWaiting = () => {
  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Kiosk Waiting");
    }
  }, []);

  return (
    <KioskLayout>
      <span className="max-w-[85vw] text-white text-NotoSans-Bold text-8xl leading-tight my-auto">
        Use your phone to fill out the form and continue
      </span>
    </KioskLayout>
  );
};
