import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { RulesModal } from "components";
import * as Yup from "yup";
import clsx from "clsx";
import { useMobileDeviceContext } from "contexts";
import { trackPageview } from "utils/plausible";

const schema = Yup.object({
  name: Yup.string().required("Name is required."),
  email: Yup.string().required("Email is required.").email("Email is invalid."),
  phoneNumber: Yup.string()
    .required("Phone is required.")
    .matches(/^([0-9]{10})$/, "Please use 000-000-0000 format."),
  agreedTerms: Yup.boolean().oneOf([true], "check it"),
  testAnswer: Yup.number()
    .required("Please answer this question.")
    .oneOf([1], "Please test again."),
  isCardholder: Yup.string(),
  hasTravelCC: Yup.string(),
});

export const MobileLeadGenForm = () => {
  const { onSubmitParticipantInfo } = useMobileDeviceContext();
  const [isRulesModalOpened, setRulesModalOpened] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    /* defaultValues: {
      name: "John Doe",
      email: "john@doe.com",
      phoneNumber: "123-456-7890",
      agreedTerms: true,
      testAnswer: 1,
      isCardholder: "prefer-not-to-answer",
      hasTravelCC: "prefer-not-to-answer",
    }, */
  });

  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Mobile Lead Form");
    }
  }, []);

  return (
    <div className="mt-20">
      <div className="relative w-full h-[8rem]">
        <div className="absolute w-full h-full border-b border-[#fff] border-b-[6.5rem] border-r border-r-[transparent] border-r-[100vw]" />
        <div className="absolute w-full h-full px-4">
          <div className="flex flex-col max-w-3xl mx-auto">
            <img
              src={require("assets/westjet-card.png")}
              className="w-48"
              alt="WESTJET"
            />
          </div>
        </div>
      </div>

      <div className="w-full bg-white">
        <div className="flex flex-col p-4 max-w-3xl mx-auto">
          <div className="text-[#0051a5] text-xl font-bold">
            The WestJet RBC
            <span className="text-[10px] align-super">&reg;</span> World Elite
            Mastercard<span className="text-[10px] align-super">‡</span>
          </div>
          <div className="text-[#00a19b] text-xl font-bold">
            Spin to Win<span className="text-[10px] align-super">*</span>{" "}
            Contest
          </div>

          <div className="my-4">
            Please complete the below information to participate in the contest.
          </div>

          <form onSubmit={handleSubmit(onSubmitParticipantInfo)}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Participant First and Last Name
              </label>

              <div className="mt-2">
                <input
                  id="name"
                  {...register("name")}
                  placeholder="Participant Name"
                  type="text"
                  className={clsx(
                    "block w-full rounded-md border px-4 py-3 focus:ring-1 focus:ring-inset focus:outline-none focus-within:outline-none",
                    !!errors.name?.message
                      ? "text-red-600 placeholder-red-300 border-red-500 focus:ring-red-500"
                      : "text-gray-900 placeholder:text-[#afb0b0] border-[#0050a4] focus:ring-[#0050a4]"
                  )}
                  aria-invalid={!!errors.name?.message}
                />
              </div>
              {!!errors.name?.message && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.name?.message}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email Address
              </label>

              <div className="mt-2">
                <input
                  id="email"
                  {...register("email")}
                  placeholder="johnsmith@gmail.com"
                  type="text"
                  className={clsx(
                    "block w-full rounded-md border px-4 py-3 focus:ring-1 focus:ring-inset focus:outline-none focus-within:outline-none",
                    !!errors.email?.message
                      ? "text-red-600 placeholder-red-300 border-red-500 focus:ring-red-500"
                      : "text-gray-900 placeholder:text-[#afb0b0] border-[#0050a4] focus:ring-[#0050a4]"
                  )}
                  aria-invalid={!!errors.email?.message}
                />
              </div>
              {!!errors.email?.message && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.email?.message}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Phone Number
              </label>

              <div className="mt-2">
                <input
                  id="phoneNumber"
                  {...register("phoneNumber")}
                  placeholder="000-000-0000"
                  type="text"
                  className={clsx(
                    "block w-full rounded-md border px-4 py-3 focus:ring-1 focus:ring-inset focus:outline-none focus-within:outline-none",
                    !!errors.phoneNumber?.message
                      ? "text-red-600 placeholder-red-300 border-red-500 focus:ring-red-500"
                      : "text-gray-900 placeholder:text-[#afb0b0] border-[#0050a4] focus:ring-[#0050a4]"
                  )}
                  aria-invalid={!!errors.phoneNumber?.message}
                />
              </div>
              {!!errors.phoneNumber?.message && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.phoneNumber?.message}
                </p>
              )}
            </div>

            <div
              className={clsx(
                "flex items-center gap-x-3 rounded-md border px-4 py-3 bg-[#e5f4f4] mb-4",
                !!errors.agreedTerms?.message
                  ? "border-red-500"
                  : " border-[#0050a4]"
              )}
            >
              <input
                id="agreedTerms"
                type="checkbox"
                {...register("agreedTerms")}
                className="h-4 w-4 border-gray-300 focus:ring-indigo-600"
              />
              <label
                htmlFor="agreedTerms"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                I have read the&nbsp;
                <span
                  className="font-bold text-[#0050a4] border-b border-[#0050a4]"
                  onClick={() => setRulesModalOpened(true)}
                >
                  rules
                </span>
                &nbsp;and accept them
              </label>
            </div>

            <div className="rounded-md border border-[#0050a4] px-4 py-3 mb-4">
              <label
                htmlFor="testAnswer"
                className="block text-sm font-medium text-gray-900"
              >
                Please correctly answer this skill testing question in order to
                be awarded a prize:
              </label>

              <div className="flex items-center gap-x-3 mt-2">
                <span className="font-semibold leading-6 whitespace-nowrap">
                  5 + 2 - (1 x 6) =
                </span>
                <input
                  id="testAnswer"
                  {...register("testAnswer")}
                  placeholder="0000"
                  className={clsx(
                    "max-w-[8rem] rounded-md border px-4 py-3 focus:ring-1 focus:ring-inset focus:outline-none focus-within:outline-none",
                    !!errors.testAnswer?.message
                      ? "text-red-600 placeholder-red-300 border-red-500 focus:ring-red-500"
                      : "text-gray-900 placeholder:text-[#afb0b0] border-[#0050a4] focus:ring-[#0050a4]"
                  )}
                />
              </div>
            </div>

            <div className="rounded-md border border-[#0050a4] px-4 py-3 mb-4">
              <label className="block text-sm font-medium text-gray-900">
                Are you a current WestJet RBC® Mastercard cardholder?
              </label>

              <div className="flex items-center gap-x-3 mt-2">
                <input
                  id="isCardholder-yes"
                  {...register("isCardholder")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="yes"
                />
                <label
                  htmlFor="isCardholder-yes"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Yes
                </label>
                <input
                  id="isCardholder-no"
                  {...register("isCardholder")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="no"
                />
                <label
                  htmlFor="isCardholder-no"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  No
                </label>
                <input
                  id="isCardholder-not-to-answer"
                  {...register("isCardholder")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="prefer-not-to-answer"
                />
                <label
                  htmlFor="isCardholder-not-to-answer"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Prefer not to answer
                </label>
              </div>
            </div>

            <div className="rounded-md border border-[#0050a4] px-4 py-3 mb-4">
              <label className="block text-sm font-medium text-gray-900">
                Do you currently have a travel credit card?
              </label>

              <div className="flex items-center gap-x-3 mt-2">
                <input
                  id="hasTravelCC-yes"
                  {...register("hasTravelCC")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="yes"
                />
                <label
                  htmlFor="hasTravelCC-yes"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Yes
                </label>
                <input
                  id="hasTravelCC-no"
                  {...register("hasTravelCC")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="no"
                />
                <label
                  htmlFor="hasTravelCC-no"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  No
                </label>
                <input
                  id="hasTravelCC-not-to-answer"
                  {...register("hasTravelCC")}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  value="prefer-not-to-answer"
                />
                <label
                  htmlFor="hasTravelCC-not-to-answer"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Prefer not to answer
                </label>
              </div>
            </div>

            <div className="rounded-md border border-[#0050a4] px-4 py-3 mb-4">
              <div className="flex items-center gap-x-3">
                <span>Ready to spin?</span>
                <button
                  type="submit"
                  className="bg-[#29bcb0] border-[#0050a4] text-white px-10 py-2 uppercase rounded-md"
                  // onClick={handleSubmit(handleSubmitForm)}
                >
                  play now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <RulesModal
        opened={isRulesModalOpened}
        onClose={() => setRulesModalOpened(false)}
      />
    </div>
  );
};
