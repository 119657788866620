export const SESSION_STATUS = {
  INITIAL: "INITIAL",
  WAITING: "WAITING",
  PRIZES_RESET: "PRIZES_RESET",
  GAME_READY: "GAME_READY",
  GAME_PLAYING: "GAME_PLAYING",
  GAME_OVER: "GAME_OVER",
  DISCONNECTED: "DISCONNECTED",
};

export const KIOSK_SCREEN = {
  HOME: "HOME",
  WAITING: "WAITING",
  GAME: "GAME",
  THANK_YOU: "THANK_YOU",
};

export const MOBILE_SCREEN = {
  FILLING_FORM: "FILLING_FORM",
  GAME: "GAME",
  THANK_YOU: "THANK_YOU",
  DISCONNECTED: "DISCONNECTED",
};

export const DEFAULT_PRIZES = [
  {
    id: "p-1",
    type: "luggage-tag",
    title: "Luggage tag",
    remaining: 44,
    weight: 21.15,
  },
  {
    id: "p-2",
    type: "lip-balm",
    title: "Lip balm",
    remaining: 19,
    weight: 4.57,
  },
  {
    id: "p-3",
    type: "west-jet-dollars",
    amount: 15,
    title: "15 WestJet dollars",
    remaining: 12,
    weight: 2.88,
  },
  {
    id: "p-4",
    type: "luggage-tag",
    title: "Luggage tag",
    remaining: 44,
    weight: 21.15,
  },
  {
    id: "p-5",
    type: "west-jet-dollars",
    amount: 25,
    title: "25 WestJet dollars",
    remaining: 3,
    weight: 0.36,
  },
  {
    id: "p-6",
    type: "west-jet-dollars",
    amount: 15,
    title: "15 WestJet dollars",
    remaining: 12,
    weight: 2.88,
  },
  {
    id: "p-7",
    type: "luggage-tag",
    title: "Luggage tag",
    remaining: 44,
    weight: 21.15,
  },
  {
    id: "p-8",
    type: "west-jet-dollars",
    amount: 99,
    title: "99 WestJet dollars",
    remaining: 1,
    weight: 0.12,
  },
  {
    id: "p-9",
    type: "luggage-tag",
    title: "Luggage tag",
    remaining: 44,
    weight: 21.15,
  },
  {
    id: "p-10",
    type: "lip-balm",
    title: "Lip balm",
    remaining: 19,
    weight: 4.57,
  },
];
