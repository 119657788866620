import { useState } from "react";
import { SettingsModal } from "./SettingsModal";
import clsx from "clsx";

export const KioskLayout = ({ className, children }) => {
  const [isModalOpened, setModalOpened] = useState(false);

  const handleClickButton = (evt) => {
    // if (evt.detail !== 3) return;
    setModalOpened(true);
  };

  return (
    <div
      className={clsx(
        "relative w-full min-h-[120rem] max-w-[1080px] max-h-[1920px] overflow-hidden flex flex-col items-center py-24",
        className
      )}
    >
      {children}

      <button
        className="absolute left-0 top-0 w-24 h-24 bg-transparent"
        onClick={handleClickButton}
      />

      <SettingsModal
        opened={isModalOpened}
        onClose={() => setModalOpened(false)}
      />
    </div>
  );
};
