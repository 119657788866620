import { useMobileDevice } from "hooks";
import { createContext, useContext, useEffect, useState } from "react";
import {
  MOBILE_SCREEN,
  SESSION_STATUS,
  getSessionStatus,
  moveNextStep,
  registerParticipantInfo,
  startSpinGame,
  subscribeSession,
} from "utils";

export const MobileDeviceContext = createContext([{}, () => null]);

export const MobileDeviceProvider = ({ children }) => {
  const isMobile = useMobileDevice();
  const [isSpinDisabled, setSpinDisabled] = useState(false);
  const [mustStartSpinning, setMustStartSpinning] = useState(false);
  const [winPrize, setWinPrize] = useState(null);
  const [screen, setScreen] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    if (isMobile !== true) return;

    const sessionId = new URLSearchParams(window.location.search).get(
      "sessionId"
    );

    if (!sessionId) return;

    init(sessionId);

    const unsubscribe = subscribeSession(sessionId, onChangeSessionStatus);

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [isMobile]);

  const init = async (sessionId) => {
    const currentStatus = await getSessionStatus(sessionId);

    if (currentStatus === SESSION_STATUS.INITIAL) {
      setScreen(MOBILE_SCREEN.FILLING_FORM);
      await moveNextStep(sessionId, SESSION_STATUS.WAITING);
    }
    setSessionId(sessionId);
  };

  const onChangeSessionStatus = async ({ status, winPrize }) => {
    if (!Object.values(SESSION_STATUS).includes(status)) return;

    setSpinDisabled(true);
    setMustStartSpinning(false);

    if (status === SESSION_STATUS.INITIAL) setScreen(null);
    else if (status === SESSION_STATUS.WAITING)
      setScreen(MOBILE_SCREEN.FILLING_FORM);
    else if (status === SESSION_STATUS.GAME_READY) {
      setSpinDisabled(false);
      setScreen(MOBILE_SCREEN.GAME);
    } else if (status === SESSION_STATUS.GAME_PLAYING) {
      setWinPrize(winPrize);
      setMustStartSpinning(true);
      setScreen(MOBILE_SCREEN.GAME);
    } else if (status === SESSION_STATUS.GAME_OVER) {
      setWinPrize(winPrize);
      setScreen(MOBILE_SCREEN.THANK_YOU);
    } else if (status === SESSION_STATUS.DISCONNECTED) {
      setScreen(MOBILE_SCREEN.DISCONNECTED);
    }
  };

  const onSubmitParticipantInfo = async (participant) => {
    await registerParticipantInfo(sessionId, participant);
  };

  const doStartSpinGame = async () => {
    if (isSpinDisabled) return;

    startSpinGame(sessionId);

    setSpinDisabled(true);
  };

  return (
    <MobileDeviceContext.Provider
      value={{
        screen,
        onSubmitParticipantInfo,
        isSpinDisabled,
        doStartSpinGame,
        winPrize,
        mustStartSpinning,
      }}
    >
      {children}
    </MobileDeviceContext.Provider>
  );
};

export const useMobileDeviceContext = () => useContext(MobileDeviceContext);
