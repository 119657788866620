import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getPrizes, resetPrizes } from "utils";
import { useKioskDeviceContext } from "contexts";

export const SettingsModal = ({ opened, onClose }) => {
  const { sessionId, resetKioskSession } = useKioskDeviceContext();
  const [prizes, setPrizes] = useState({});

  useEffect(() => {
    if (!opened) return;

    const prizes = {
      "luggage-tag": {
        title: "Luggage tag",
        remaining: 0,
      },
      "lip-balm": {
        title: "Lip balm",
        remaining: 0,
      },
      "15-west-jet-dollars": {
        amount: 15,
        title: "15 WestJet dollars",
        remaining: 0,
      },
      "25-west-jet-dollars": {
        amount: 25,
        title: "25 WestJet dollars",
        remaining: 0,
      },
      "99-west-jet-dollars": {
        amount: 99,
        title: "99 WestJet dollars",
        remaining: 0,
      },
    };

    getPrizes()
      .then((_prizes) => {
        for (const { type, remaining, amount } of _prizes) {
          if (type === "west-jet-dollars") {
            prizes[`${amount}-${type}`].remaining += remaining;
          } else {
            prizes[type].remaining += remaining;
          }
        }
      })
      .finally(() => setPrizes(prizes));
  }, [opened]);

  const handleClickResetPrizes = async () => {
    await resetPrizes(sessionId);
  };

  const handleClickResetKiosk = () => {
    resetKioskSession();
  };

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-end p-4 text-center items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-[37.5vw] flex flex-col items-center justify-between">
                <div className="w-full px-4 pt-12 pb-4 mb-4">
                  <Dialog.Title
                    as="h1"
                    className="text-2xl font-bold text-gray-900 text-center"
                  >
                    Prize Amounts
                  </Dialog.Title>
                </div>

                <div className="w-full px-4 mb-4">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-gray-900 sm:pl-6"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xl font-semibold text-gray-900"
                        >
                          Prize Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xl font-semibold text-gray-900"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {Object.keys(prizes).map((key, pIdx) => (
                        <tr
                          key={key}
                          className={pIdx % 2 === 0 ? undefined : "bg-gray-50"}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                            {pIdx + 1}
                          </td>
                          <td className="text-left whitespace-nowrap px-3 py-4 text-xl text-gray-500">
                            {prizes[key].title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-xl text-gray-500">
                            {prizes[key].remaining}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-col w-full px-6 py-4">
                  <div className="flex justify-between mb-8">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-red-600 px-6 py-4 text-white shadow-sm hover:bg-red-500 w-auto text-xl"
                      onClick={handleClickResetPrizes}
                    >
                      Reset Prizes
                    </button>

                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-red-600 px-6 py-4 text-white shadow-sm hover:bg-red-500 w-auto text-xl"
                      onClick={handleClickResetKiosk}
                    >
                      Reset Kiosk
                    </button>
                  </div>

                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-gray-600 px-6 py-4 text-white shadow-sm hover:bg-gray-500 w-auto text-xl"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
