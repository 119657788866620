import { useMobileDeviceContext } from "contexts";
import { Fragment, useEffect } from "react";
import { trackPageview } from "utils/plausible";

export const MobileThankYou = () => {
  const { winPrize } = useMobileDeviceContext();

  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Mobile Thank you");
    }
  }, []);

  if (winPrize === null) return <Fragment />;

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-[#0051a5]">
      <span className="text-white text-6xl text-NotoSans-Bold my-16">
        You win!
      </span>

      <div className="p-2 bg-[#0970cd] rounded-full">
        <div className="border-[3px] border-white w-[90vw] h-[90vw] bg-[#71cac9] rounded-full flex flex-col items-center justify-around">
          {winPrize.type === "west-jet-dollars" ? (
            <>
              <span className="text-NotoSans-Bold text-[#0051a5] text-9xl font-bold leading-none mt-auto">
                {winPrize.amount}
              </span>

              <span className="text-NotoSans-Bold text-[#0051a5] uppercase text-5xl text-center mb-auto">
                WestJet dollars
              </span>
            </>
          ) : (
            <>
              <span className="text-NotoSans-Bold text-[#0051a5] uppercase text-5xl text-center mt-8 mx-12">
                {winPrize.title}
              </span>

              <div className="-mt-4">
                <img
                  src={require(`assets/${winPrize.type}.png`)}
                  alt={winPrize.title}
                  width={200}
                  height={200}
                  className="object-contain	"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <span className="max-w-[80vw] text-white font-bold text-4xl leading-tight	mt-16">
        Thank you
        <br />
        for playing
      </span>
    </div>
  );
};
