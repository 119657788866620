import { KioskLayout } from "components";
import { useKioskDeviceContext } from "contexts";
import { Fragment, useEffect } from "react";
import { trackEvent, trackPageview } from "utils/plausible";

export const KioskWin = () => {
  const { winPrize } = useKioskDeviceContext();

  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Kiosk Win");
    }
  }, []);

  useEffect(() => {
    if (!winPrize) return;

    trackEvent("Prize Won", {
      props: { "Prize Won": winPrize.title },
    });
  }, [winPrize]);

  if (winPrize === null) return <Fragment />;

  return (
    <KioskLayout className="bg-[#0051a5]">
      <span className="text-white text-9xl text-NotoSans-Bold mb-24">
        You win!
      </span>

      <div className="p-8 bg-[#0970cd] rounded-full">
        <div className="border-[3px] border-white w-[930px] h-[930px] bg-[#71cac9] rounded-full flex flex-col items-center justify-center">
          {winPrize.type === "west-jet-dollars" ? (
            <>
              <span className="text-NotoSans-Bold text-[#0051a5] text-[18rem] font-bold leading-none -mt-16">
                {winPrize.amount}
              </span>

              <span className="text-NotoSans-Bold text-[#0051a5] uppercase text-9xl text-center">
                WestJet dollars
              </span>
            </>
          ) : (
            <>
              <span className="text-NotoSans-Bold text-[#0051a5] uppercase text-9xl text-center mx-20">
                {winPrize.title}
              </span>

              <div className="w-[35rem] h-[35rem] -mb-[5rem]">
                <img
                  src={require(`assets/${winPrize.type}.png`)}
                  alt={winPrize.title}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <span className="max-w-[80vw] text-white font-bold text-8xl leading-tight	my-auto">
        Thank you
        <br />
        for playing
      </span>
    </KioskLayout>
  );
};
