import {
  KioskDeviceProvider,
  MobileDeviceProvider,
  useKioskDeviceContext,
  useMobileDeviceContext,
} from "contexts";
import { useMobileDevice } from "hooks";
import { Fragment } from "react";
import {
  KioskHome,
  KioskWaiting,
  KioskSpinToWin,
  KioskWin,
  MobileLeadGenForm,
  MobileSpinToWin,
  MobileThankYou,
  MobileDisconnected,
} from "screens";
import { KIOSK_SCREEN, MOBILE_SCREEN } from "utils";
import "utils/firebase";

const MobileContent = () => {
  const { screen } = useMobileDeviceContext();

  if (screen === MOBILE_SCREEN.FILLING_FORM) return <MobileLeadGenForm />;

  if (screen === MOBILE_SCREEN.GAME) return <MobileSpinToWin />;

  if (screen === MOBILE_SCREEN.THANK_YOU) return <MobileThankYou />;

  if (screen === MOBILE_SCREEN.DISCONNECTED) return <MobileDisconnected />;

  return <Fragment />;
};

const KioskContent = () => {
  const { screen } = useKioskDeviceContext();

  if (screen === KIOSK_SCREEN.HOME) return <KioskHome />;

  if (screen === KIOSK_SCREEN.WAITING) return <KioskWaiting />;

  if (screen === KIOSK_SCREEN.GAME) return <KioskSpinToWin />;

  if (screen === KIOSK_SCREEN.THANK_YOU) return <KioskWin />;

  return <Fragment />;
};

const App = () => {
  const isMobile = useMobileDevice();

  if (isMobile === null) return <Fragment />;

  if (isMobile)
    return (
      <MobileDeviceProvider>
        <MobileContent />
      </MobileDeviceProvider>
    );

  return (
    <KioskDeviceProvider>
      <KioskContent />
    </KioskDeviceProvider>
  );
};

export default App;
