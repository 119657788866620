import { useEffect } from "react";
import { trackPageview } from "utils/plausible";

export const MobileDisconnected = () => {
  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Mobile Disconnected");
    }
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col">
      <span className="max-w-[80vw] text-white text-4xl m-auto">
        Disconnected, thanks for playing!
      </span>
    </div>
  );
};
