import { DEFAULT_PRIZES, SESSION_STATUS } from "./constants";
import { db } from "./firebase";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import dayjs from "dayjs";

const kioskId = process.env.REACT_APP_KIOSK_ID;

const getSessionDocSnapshot = async (id, raiseErrorIfNotExisting = true) => {
  if (id === null) throw Error("Session id is null.");

  const ref = doc(db, "Kiosks", kioskId, "Sessions", `${id}`);
  const docSnapshot = await getDoc(ref);

  if (raiseErrorIfNotExisting && !docSnapshot.exists())
    throw Error("Session is not existing.");

  return { ref, docSnapshot };
};

export const initSession = async (id = null) => {
  const prizes = await getPrizes();

  if (!prizes.length) throw Error("No Prize.");

  try {
    const { ref, docSnapshot } = await getSessionDocSnapshot(id, false);

    if (!docSnapshot.exists()) {
      await setDoc(ref, {
        status: SESSION_STATUS.INITIAL,
      });
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const moveNextStep = async (id, status) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    await setDoc(
      ref,
      {
        status,
      },
      { merge: true }
    );
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getSessionStatus = async (id) => {
  try {
    const { docSnapshot } = await getSessionDocSnapshot(id);

    return docSnapshot.data().status;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const disconnectSession = async (id) => {
  try {
    await moveNextStep(id, SESSION_STATUS.DISCONNECTED);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const subscribeSession = async (id, callback) =>
  onSnapshot(doc(db, "Kiosks", kioskId, "Sessions", id), (doc) => {
    const session = doc.data();

    if (Object.values(SESSION_STATUS).includes(session?.status))
      callback({
        status: session.status,
        winPrize: session?.winPrize || null,
      });
  });

export const registerParticipantInfo = async (id, participant) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    await setDoc(
      ref,
      {
        participant,
      },
      { merge: true }
    );

    await moveNextStep(id, SESSION_STATUS.GAME_READY);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const resetPrizes = async (id) => {
  try {
    await setDoc(
      doc(db, "Kiosks", kioskId),
      {
        prizes: DEFAULT_PRIZES,
      },
      { merge: true }
    );

    await moveNextStep(id, SESSION_STATUS.PRIZES_RESET);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getPrizes = async () => {
  try {
    const docSnapshot = await getDoc(doc(db, "Kiosks", kioskId));

    if (docSnapshot.exists()) {
      return docSnapshot.data().prizes.filter(({ remaining }) => remaining > 0);
    }

    return [];
  } catch (error) {}
};

const getWeighedPrizeNumber = (options) => {
  var i;

  var weights = [options[0].weight];

  for (i = 1; i < options.length; i++)
    weights[i] = options[i].weight + weights[i - 1];

  var random = Math.random() * weights[weights.length - 1];

  for (i = 0; i < weights.length; i++) if (weights[i] > random) break;

  return i;
};

export const startSpinGame = async (id) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    const prizes = await getPrizes();

    if (!prizes.length) throw Error("No Prize.");

    const prizeNumber = getWeighedPrizeNumber(prizes);

    delete prizes[prizeNumber].remaining;
    delete prizes[prizeNumber].weight;

    await setDoc(
      ref,
      {
        winPrize: prizes[prizeNumber],
      },
      { merge: true }
    );

    await moveNextStep(id, SESSION_STATUS.GAME_PLAYING);

    return prizeNumber;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const endSpinGame = async (id) => {
  try {
    const { ref, docSnapshot } = await getSessionDocSnapshot(id);
    const session = docSnapshot.data();

    const prizes = await getPrizes();

    const date = new Date();

    await setDoc(
      doc(db, "Kiosks", kioskId),
      {
        prizes: prizes.map((prize) =>
          prize.id === session.winPrize.id
            ? { ...prize, remaining: prize.remaining - 1 }
            : prize
        ),
      },
      { merge: true }
    );
    await moveNextStep(id, SESSION_STATUS.GAME_OVER);

    await setDoc(
      ref,
      {
        endedAt: date,
      },
      { merge: true }
    );
  } catch (error) {
    console.log("error: ", error);
  }
};
