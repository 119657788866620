import { useEffect, useState } from "react";
import { Wheel } from "./Wheel";
import { getPrizes } from "utils";
import { useKioskDeviceContext, useMobileDeviceContext } from "contexts";
import { useMobileDevice } from "hooks";

export const Roulette = () => {
  const isMobile = useMobileDevice();
  const [isLoaded, setLoaded] = useState(false);
  const [availablePrizes, setAvailablePrizes] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(-1);
  const mobile = useMobileDeviceContext();
  const kiosk = useKioskDeviceContext();

  useEffect(() => {
    setLoaded(false);

    getPrizes()
      .then((prizes) =>
        setAvailablePrizes(
          prizes.map(({ id, title: option }) => ({ id, option }))
        )
      )
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (isMobile === null || !availablePrizes.length) return;

    const { mustStartSpinning, winPrize } = isMobile ? mobile : kiosk;

    setMustSpin(mustStartSpinning);

    if (winPrize) {
      const prizeNumber = availablePrizes.findIndex(
        ({ id }) => id === winPrize.id
      );
      setPrizeNumber(prizeNumber);
    }
  }, [isMobile, availablePrizes, mobile, kiosk]);

  const handleStopSpinning = async () => {
    if (isMobile === false) {
      await kiosk.doEndGame();
    }
  };

  if (!isLoaded || isMobile === null || !availablePrizes.length) return <></>;

  return (
    <div className={`relative p-${isMobile ? 2 : 8} bg-[#0970cd] rounded-full`}>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={availablePrizes}
        outerBorderColor="#71cac9"
        innerRadius={15}
        radiusLineWidth={0}
        innerBorderWidth={3}
        innerBorderColor={"#ffffff"}
        textColors={["#ffffff"]}
        backgroundColors={[
          "#8fd3d2",
          "#a9dbdb",
          "#29bcb0",
          "#a9dbdb",
          "#29bcb0",
        ]}
        isMobile={isMobile}
        onStopSpinning={handleStopSpinning}
      />
    </div>
  );
};
