export const getRotationDegrees = (prizeNumber, numberOfPrizes) => {
  const degreesPerPrize = 360 / numberOfPrizes;

  const initialRotation = 15 + degreesPerPrize / 2;

  const perfectRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) - initialRotation;

  const rotationDegrees =
    numberOfPrizes - prizeNumber > numberOfPrizes / 2
      ? -360 + perfectRotation
      : perfectRotation;

  return rotationDegrees;
};

export const clamp = (min, max, val) => Math.min(Math.max(min, +val), max);

export const getQuantity = (prizeMap) => prizeMap.slice(-1)[0].slice(-1)[0] + 1;

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const makeClassKey = (length) => {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
