import { Roulette } from "components";
import { useMobileDeviceContext } from "contexts";
import { useEffect } from "react";
import { trackPageview } from "utils/plausible";

export const MobileSpinToWin = () => {
  const { isSpinDisabled, doStartSpinGame } = useMobileDeviceContext();

  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Mobile Spin-to-Win");
    }
  }, []);

  return (
    <div className="w-full h-full min-h-screen flex flex-col items-center justify-center">
      <span className="text-white text-5xl text-NotoSans-Bold">
        Spin to win<span className="text-xl align-super">*</span>
      </span>

      <div className="w-full">
        <div className="w-fit my-16 mx-auto max-w-sm">
          <Roulette />
        </div>
      </div>

      <button
        className="bg-[#29bcb0] border-2 border-[#fff] text-white px-20 py-2 uppercase rounded-md disabled:bg-[#71cac9]"
        disabled={isSpinDisabled}
        onClick={doStartSpinGame}
      >
        Spin
      </button>
    </div>
  );
};
