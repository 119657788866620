import { KioskLayout } from "components";
import { useKioskDeviceContext } from "contexts";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import { trackPageview } from "utils/plausible";

export const KioskHome = () => {
  const { isEnableToPlay, sessionId } = useKioskDeviceContext();

  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Kiosk Home");
    }
  }, []);

  return (
    <KioskLayout key={sessionId}>
      <div className="absolute top-0 right-0">
        <img src={require("assets/CALL TO ACTION.jpg")} alt="Call to Action" />
      </div>

      {isEnableToPlay && (
        <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
          <div className="mb-10 p-4 bg-white">
            <QRCode
              size={368}
              value={`${window.location.origin}?sessionId=${sessionId}`}
              viewBox={`0 0 368 368`}
            />
          </div>
        </div>
      )}
    </KioskLayout>
  );
};
