import { useMobileDevice } from "hooks";
import { createContext, useContext, useEffect, useState } from "react";
import {
  KIOSK_SCREEN,
  SESSION_STATUS,
  endSpinGame,
  initSession,
  subscribeSession,
  disconnectSession,
} from "utils";
import { v4 as uuidv4 } from "uuid";

export const KioskDeviceContext = createContext([{}, () => null]);

export const KioskDeviceProvider = ({ children }) => {
  const isMobile = useMobileDevice();
  const [mustStartSpinning, setMustStartSpinning] = useState(false);
  const [winPrize, setWinPrize] = useState(null);
  const [screen, setScreen] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isEnableToPlay, setEnableToPlay] = useState(false);

  useEffect(() => {
    if (isMobile !== false) return;

    // const sessionId = "a333d5c7-5c5a-46cb-9090-2be178c3df8b";
    const sessionId = uuidv4();

    setSessionId(sessionId);
  }, [isMobile]);

  useEffect(() => {
    if (sessionId === null) return;

    setEnableToPlay(false);
    init(sessionId);

    const unsubscribe = subscribeSession(sessionId, onChangeSessionStatus);

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [sessionId]);

  const init = async (sessionId) => {
    try {
      await initSession(sessionId);

      setEnableToPlay(true);
    } catch (error) {
      setEnableToPlay(false);
    }

    setScreen(KIOSK_SCREEN.HOME);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const onChangeSessionStatus = async ({ status, winPrize }) => {
    if (!Object.values(SESSION_STATUS).includes(status)) return;

    setMustStartSpinning(false);

    if (status === SESSION_STATUS.INITIAL) setScreen(KIOSK_SCREEN.HOME);
    else if (status === SESSION_STATUS.PRIZES_RESET) {
      await resetKioskSession();

      setScreen(KIOSK_SCREEN.HOME);
    } else if (status === SESSION_STATUS.WAITING)
      setScreen(KIOSK_SCREEN.WAITING);
    else if (status === SESSION_STATUS.GAME_READY) setScreen(KIOSK_SCREEN.GAME);
    else if (status === SESSION_STATUS.GAME_PLAYING) {
      setWinPrize(winPrize);
      setMustStartSpinning(true);
      setScreen(KIOSK_SCREEN.GAME);
    } else if (status === SESSION_STATUS.GAME_OVER) {
      setWinPrize(winPrize);
      setScreen(KIOSK_SCREEN.THANK_YOU);

      const timeoutId = setTimeout(async () => {
        await resetKioskSession();
      }, 15 * 1000);
      setTimeoutId(timeoutId);
    }
  };

  const doEndGame = async () => await endSpinGame(sessionId);

  const resetKioskSession = async () => {
    await disconnectSession(sessionId);

    setSessionId(uuidv4());
  };

  return (
    <KioskDeviceContext.Provider
      value={{
        isEnableToPlay,
        sessionId,
        screen,
        winPrize,
        mustStartSpinning,
        doEndGame,
        resetKioskSession,
      }}
    >
      {children}
    </KioskDeviceContext.Provider>
  );
};

export const useKioskDeviceContext = () => useContext(KioskDeviceContext);
