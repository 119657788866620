import { KioskLayout, Roulette } from "components";
import { useEffect } from "react";
import { trackPageview } from "utils/plausible";

export const KioskSpinToWin = () => {
  useEffect(() => {
    if (typeof trackPageview === "function") {
      trackPageview("Kiosk Spin-to-Win");
    }
  }, []);

  return (
    <KioskLayout>
      <div className="text-white text-9xl text-NotoSans-Bold mb-24">
        Spin to win<span className="text-5xl align-super">*</span>
      </div>

      <Roulette />
    </KioskLayout>
  );
};
