import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { styled } from "styled-components";

const StyledSpan = styled("span")(() => ({
  fontFamily: "Calibri",
  fontSize: 14,
  color: "rgb(0,0,0)",
}));

export const RulesModal = ({ opened, onClose }) => {
  const [visibility, setVisibility] = useState("hidden");
  const scrollRef = useRef(null);

  useEffect(() => {
    setVisibility("hidden");
  }, [opened]);

  const handleAfterRender = () => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollTo(0, 0);
    setVisibility("visible");
  };

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
              afterEnter={handleAfterRender}
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-[90vw] justify-between p-4">
                <Dialog.Title
                  as="h1"
                  className="w-full text-xl font-bold text-gray-900 text-left mb-4"
                >
                  Terms and Conditions
                </Dialog.Title>

                <div
                  ref={scrollRef}
                  className="border border-[#0050a4] p-4 rounded-md max-h-[65vh] overflow-auto"
                  style={{ visibility }}
                >
                  <p>
                    <strong>
                      <StyledSpan>
                        WestJet World Elite Mastercard Spin to Win Contest
                      </StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <u>
                        <StyledSpan
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Standard data rates may apply to participation in the
                          Contest via a mobile device. &nbsp;Please contact your
                          service provider for pricing and service plan
                          information and rates before participation.
                        </StyledSpan>
                      </u>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>
                        FULL CONTEST RULES (THE “CONTEST RULES”):
                      </StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>CONTEST OVERVIEW</StyledSpan>
                    </strong>
                    <StyledSpan>: The&nbsp;</StyledSpan>
                    <strong>
                      <StyledSpan>
                        WestJet World Elite Mastercard Spin to Win Contest
                      </StyledSpan>
                    </strong>
                    <StyledSpan>&nbsp;(the “</StyledSpan>
                    <strong>
                      <StyledSpan>Contest</StyledSpan>
                    </strong>
                    <StyledSpan>”) is sponsored by&nbsp;</StyledSpan>
                    <StyledSpan>WestJet, an Alberta partnership</StyledSpan>
                    <StyledSpan>&nbsp;(the “</StyledSpan>
                    <strong>
                      <StyledSpan>Sponsor</StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”) and is administered by Armstrong Partnership LP (the “
                    </StyledSpan>
                    <strong>
                      <StyledSpan>Administrator</StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”). The Contest runs daily from July 17 to August 13,
                      2023, inclusive (the “
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Contest Period
                      </StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”), commencing each day at 7:00:01 a.m. Mountain Daylight
                      Time (“
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        MDT
                      </StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”) and ending each day at 1:59:59 p.m. MDT (each such
                      daily time period, a “
                    </StyledSpan>
                    <strong>
                      <StyledSpan>Contest Day</StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”) and is subject to these official Contest Rules.&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>NO PURCHASE NECESSARY&nbsp;</StyledSpan>
                    <StyledSpan>TO ENTER OR WIN.</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>1. ELIGIBILITY:&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>The Contest&nbsp;</StyledSpan>
                    <StyledSpan>
                      is open to individuals who (i) reside (at the time of
                      entry) in Canada,&nbsp;
                    </StyledSpan>
                    <StyledSpan>excluding Quebec</StyledSpan>
                    <StyledSpan>
                      , and (ii)&nbsp;have reached the age of majority in their
                      province/territory of residence at the time of entry.
                      Employees, officers, governors, representatives or agents
                      (and immediate family members of and those with whom such
                      persons are domiciled, whether related or not) of the
                      Sponsor, Mastercard Canada ULC
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        &nbsp;
                      </StyledSpan>
                    </strong>
                    <StyledSpan>(“</StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Mastercard
                      </StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”), the Administrator, and each of their respective parent
                      companies, related companies, affiliates, distributors,
                      subsidiaries and advertising/promotion agencies, prize
                      providers or contractors who is involved in the Contest
                      (collectively, the “
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Contest Parties
                      </StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”) are not eligible to participate in the Contest. This
                      Contest is subject to all applicable federal, provincial,
                      and local laws and regulations.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>2. HOW TO ENTER:&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p style={{ marginBottom: "10.0000pt", lineHeight: "114%" }}>
                    <StyledSpan
                      style={{
                        lineHeight: "114%",
                      }}
                    >
                      There is no purchase necessary to enter the Contest or win
                      a prize. During each Contest Day, eligible individuals who
                      are travelling at the Calgary International Airport (YYC)
                      (the “
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          lineHeight: "114%",

                          fontWeight: "bold",
                        }}
                      >
                        Airport
                      </StyledSpan>
                    </strong>
                    <StyledSpan
                      style={{
                        lineHeight: "114%",
                      }}
                    >
                      ”) in Domestic Departures, may, after clearing security at
                      the Airport, &nbsp;present themselves at the Sponsor’s
                      Contest booth, located at the entrance to Concourse B in
                      Domestic Departures (the “
                    </StyledSpan>
                    <strong>
                      <StyledSpan
                        style={{
                          lineHeight: "114%",

                          fontWeight: "bold",
                        }}
                      >
                        Contest Booth
                      </StyledSpan>
                    </strong>
                    <StyledSpan
                      style={{
                        lineHeight: "114%",
                      }}
                    >
                      ”), &nbsp;and enter the Contest at the Contest Booth
                      location by using their mobile phone, tablet or other
                      device with internet access and equipped to read a QR
                      code, to: (i) complete the official online Contest entry
                      form (which may include their name, email and contact
                      telephone number) (ii) review and agree to these official
                      Contest Rules (iii) correctly answer the required
                      skill-testing question without aid; and (iv) submit their
                      entry. (They will also be asked, at the time of entry,
                      certain optional additional travel-related questions,
                      which they may elect not to answer).&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      Upon submission of a properly-completed entry, subject to
                      and following the correct answering by the entrant of the
                      required skill-testing question, a screen at the Contest
                      Booth will display whether the entrant has won a prize
                      and, if so, the description of the prize (see Rules 3 and
                      4 below).&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      There is a limit of one (1) entry per Contest Day per
                      person. All entries become the property of Sponsor and
                      will not be acknowledged or returned. The decisions of the
                      Sponsor with respect to all aspects of this Contest are
                      final and binding on all entrants without right of appeal,
                      including, without limitation, any decisions regarding the
                      eligibility/disqualification of entries and/or entrants.
                      The Released Parties (defined below) are not responsible
                      for and will not be liable for: &nbsp;incomplete, late,
                      misdirected, stolen, or otherwise defective or
                      non-compliant entries; any error, omission, interruption,
                      defect or delay in transmission or communication;
                      technical or mechanical malfunctions; failures,
                      interruptions or malfunctions of phones, phone lines or
                      telephone systems
                    </StyledSpan>
                    <StyledSpan>
                      , network hardware or software, computers, servers,
                      equipment, cable or satellite systems, cellular towers,
                      internet service providers or wireless carriers
                    </StyledSpan>
                    <StyledSpan>
                      &nbsp;or other systems; errors in these Rules, in any
                      Contest-related advertisements or other materials; or
                      inaccurate entry information, whether caused by equipment,
                      programming used in this Contest, human processing error,
                      act of God or otherwise.
                    </StyledSpan>
                    <StyledSpan>&nbsp;</StyledSpan>
                    <StyledSpan>
                      Without limiting the generality of the foregoing, the
                      Released Parties will not be liable for any injury or
                      damage to an entrant’s or any other person’s mobile phone,
                      computer or other device related to or resulting from
                      participating in, or downloading any material in
                      connection with, the Contest.&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      All entries are subject to verification at any time. The
                      Sponsor reserves the right, in its sole discretion, to
                      require proof of identity and/or eligibility (in a form
                      acceptable to the Sponsor – including, without limitation,
                      government-issued photo identification) to participate in
                      this Contest. Failure to provide such proof to the
                      satisfaction of the Sponsor upon request may result in
                      disqualification. The sole determinant of the time for the
                      purposes of a valid entry in this Contest will be the
                      Sponsor’s official Contest clock.
                    </StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>
                      Except where prohibited by law, to&nbsp;
                    </StyledSpan>
                    <StyledSpan>the extent applicable,&nbsp;</StyledSpan>
                    <StyledSpan>entrants</StyledSpan>
                    <StyledSpan>
                      &nbsp;entrance into the contest and/or acceptance of a
                      prize constitutes winner's consent to Sponsor's and its
                      agents' use of the winner's&nbsp;
                    </StyledSpan>
                    <StyledSpan>name, likeness,&nbsp;</StyledSpan>
                    <StyledSpan>photograph</StyledSpan>
                    <StyledSpan>,</StyledSpan>
                    <StyledSpan>&nbsp;image,&nbsp;</StyledSpan>
                    <StyledSpan>
                      voice, opinions and/or city/province of residence&nbsp;
                    </StyledSpan>
                    <StyledSpan>for&nbsp;</StyledSpan>
                    <StyledSpan>advertising and promotional</StyledSpan>
                    <StyledSpan>&nbsp;purposes in perpetuity</StyledSpan>
                    <StyledSpan>
                      &nbsp;in any media, worldwide, without payment, notice or
                      consideration. You may be required to sign a document
                      confirming your consent to this effect.
                    </StyledSpan>
                    <StyledSpan>
                      &nbsp;Personal information of the entrant may be
                      transferred to and stored in jurisdictions outside of
                      Canada and therefore subject to the laws of &nbsp;other
                      jurisdictions.
                    </StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>3. PRIZES:&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>There are&nbsp;</StyledSpan>
                    <StyledSpan>242</StyledSpan>
                    <StyledSpan>&nbsp;prize</StyledSpan>
                    <StyledSpan>
                      s available to be won each Contest Day, while quantities
                      last (being a total&nbsp;
                    </StyledSpan>
                    <StyledSpan>of&nbsp;</StyledSpan>
                    <StyledSpan>
                      6776 prizes in all) available to be won during the Contest
                      Period, as set forth in more detail below. Each Contest
                      Day, the number of prizes available to be won, the
                      approximate retail value of each prize and the odds of
                      winning each prize at the outset of each Contest Day, are
                      as follows
                    </StyledSpan>
                    <StyledSpan>
                      &nbsp;(subject to any substitutions):
                    </StyledSpan>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <div align="center">
                    <table
                      style={{
                        borderCollapse: "collapse",
                        border: "none",
                      }}
                    >
                      <tbody>
                        {[
                          { title: "Luggage tags", count: "176" },
                          { title: "Lip Balms", count: "38" },
                          { title: "$15 WestJet Dollars", count: "24" },
                          { title: "$25 WestJet Dollars", count: "3" },
                          { title: "$95 WestJet Dollars", count: "1" },
                        ].map(({ title, count }, idx) => (
                          <tr key={idx}>
                            <td
                              style={{
                                padding: "0pt 5.4pt",
                                borderWidth: "1pt",
                                borderStyle: "solid",
                                borderColor: "windowtext",
                                verticalAlign: "bottom",
                              }}
                            >
                              <p style={{ textAlign: "center" }}>
                                <strong>
                                  <StyledSpan
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 15,
                                    }}
                                  >
                                    {title}
                                  </StyledSpan>
                                </strong>
                              </p>
                            </td>

                            <td
                              style={{
                                padding: "0pt 5.4pt",
                                borderLeft: "1pt solid windowtext",
                                borderRight: "1pt solid windowtext",
                                borderTop: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                verticalAlign: "bottom",
                              }}
                            >
                              <p style={{ textAlign: "center" }}>
                                <StyledSpan
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  {count}
                                </StyledSpan>
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      Prizes are selected at random, following submission of
                      each qualifying entry, by computer program
                    </StyledSpan>
                    <StyledSpan>.</StyledSpan>
                    <StyledSpan>&nbsp;The number of available&nbsp;</StyledSpan>
                    <StyledSpan>
                      prizes diminish, and chances of winning change, each
                      Contest Day, as prizes are won.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      Prizes will be awarded, subject to compliance with these
                      Contest Rules, at the Contest Booth by a representative of
                      the Sponsor (“
                    </StyledSpan>
                    <strong>
                      <StyledSpan>Sponsor Representative</StyledSpan>
                    </strong>
                    <StyledSpan>
                      ”), immediately following confirmation of an entrant’s
                      entitlement to the applicable prize. In order for an
                      eligible entrant who is awarded one of the “WestJet
                      dollars” loyalty prizes (each a “Loyalty Prize”) to
                      receive and use the Loyalty Prize, such eligible entrant
                      must be already be, or must become, a member of the
                      WestJet Rewards® program (the terms and conditions of
                      which are accessible at:&nbsp;
                    </StyledSpan>
                    <a href="https://www.westjet.com/en-ca/rewards/terms-conditions">
                      <u>
                        <StyledSpan
                          style={{
                            color: "rgb(0,0,255)",
                            textDecoration: "underline",
                          }}
                        >
                          https://www.westjet.com/en-ca/rewards/terms-conditions
                        </StyledSpan>
                      </u>
                    </a>
                    <StyledSpan>)</StyledSpan>
                    <StyledSpan>&nbsp;</StyledSpan>
                    <StyledSpan>
                      by having created a valid WestJet Rewards account. There
                      is no payment or purchase required in order to create a
                      WestJet Rewards account and enrol as a member in the
                      WestJet Rewards program. The Sponsor Representative will
                      assist each eligible entrant who is awarded a Loyalty
                      Prize, as applicable, in the following manner: (i) if the
                      eligible entrant is already enrolled as a member in the
                      WestJet Rewards program, to have the Loyalty Prize
                      credited to such eligible entrant’s account; or (ii) if
                      the eligible entrant is not already enrolled as a member
                      in the WestJet Rewards program and wishes to become a
                      member, to assist the eligible entrant in enrolling as a
                      member in the WestJet Rewards program and to have the
                      Loyalty Prize credited to such eligible entrant’s account
                    </StyledSpan>
                    <StyledSpan>.&nbsp;</StyledSpan>
                    <StyledSpan>
                      Notwithstanding anything herein, in the event that entrant
                      is to receive a Loyalty Prize, such entrant may elect to
                      receive an alternate prize of equal or greater value which
                      does not require enrolling as a member in the WestJet
                      Rewards® program or any other program or account.&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      WestJet dollars awarded as a prize have a one-year expiry
                      date. WestJet dollars can be used towards payment of the
                      base fare of an eligible flight or vacation package,
                      available on westjet.com or through WestJet’s contact
                      centre subject to any applicable terms and conditions.
                      WestJet dollars cannot be applied against taxes, fees or
                      charges and can only be used for new bookings. Minimum
                      redemption rules on flights apply when purchasing Economy,
                      Premium or Business fares; the entire base fare must be
                      paid with WestJet dollars when purchasing a Member
                      Exclusive fare. A minimum redemption amount of 50 WestJet
                      dollars per booking applies for WestJet Vacations package
                      bookings. Other restrictions may apply and are subject to
                      change at any time. All terms, conditions, and
                      restrictions applicable to WestJet Rewards are established
                      by WestJet and shall apply to all bookings, travel
                      arrangements and other services offered or provided by
                      WestJet. WestJet, not Royal Bank of Canada or Mastercard,
                      is responsible for WestJet Rewards.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      The actual retail value of the prizes depends on current
                      market values at any time. The prize winner is not
                      entitled to any differences between stated approximate
                      retail value, and the actual value, of any prize.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p
                    style={{
                      marginTop: "0.0000pt",
                      marginBottom: "0.0000pt",
                    }}
                  >
                    <StyledSpan>
                      Without limiting the generality of the foregoing, the
                      following general conditions apply to each Prize: (i)
                      Prize must be accepted as awarded, is not transferable,
                      assignable or convertible to cash, and there can be no
                      substitutions (except as may otherwise be specifically
                      permitted by Sponsor in its sole and absolute discretion);
                      (ii) Sponsor reserves the right at any time to: (a) place
                      reasonable restrictions on the availability or use of the
                      Prize or any component thereof; and (b) substitute a prize
                      or a component thereof for any reason with a prize or a
                      prize component of equal or greater value, including,
                      without limitation, but solely at the Sponsor’s sole
                      discretion, a cash award; and (iii) the Prize winner
                      agrees to waive all recourse against the Released Parties
                      (as defined below) if the Prize or a component thereof
                      does not prove satisfactory, either in whole or in part.
                    </StyledSpan>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p
                    style={{
                      marginTop: "0.0000pt",
                      marginBottom: "0.0000pt",
                    }}
                  >
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>
                        4. WINNER SELECTION / NOTIFICATION / CONFIRMATION:
                      </StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      In the event that any entrant is determined by the Sponsor
                      to be ineligible to enter the Contest or receive a prize
                      for any reason, then they will be disqualified (and
                      forfeit all rights to their prize) and the Sponsor
                      reserves
                    </StyledSpan>
                    <StyledSpan>
                      &nbsp;the right, in its sole discretion, to award the
                      prize to an alternate eligible entrant in accordance with
                      these Contest Rules. For greater certainty and the
                      avoidance of any doubt, the Sponsor does not have the
                      obligation to select an alternate eligible winner.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      By participating in this Contest and/or, as applicable,
                      accepting a prize, each entrant and prize winner hereby:
                      (i) confirms compliance with and acceptance of these
                      Contest Rules and acknowledges acceptance of the
                      applicable prize if and as awarded; (ii) agrees indemnify
                      and release the Sponsor, Contest Parties, Mastercard
                      Canada ULC, Administrator, and each of their respective
                      officers, directors, employees, agents, representatives,
                      governors, related companies, affiliates, distributors,
                      subsidiaries, advertising/promotion agencies, prize
                      providers, contractors, successors and assigns
                      (collectively, the “Released Parties”) &nbsp;against and
                      from any and all claims, damages, liabilities, costs and
                      expenses arising from or in connection, directly or
                      indirectly, with this Contest and/or the prize winner’s
                      participation therein and/or the awarding and use/misuse
                      of the prize or any portion thereof; (iii)
                    </StyledSpan>
                    <StyledSpan>&nbsp;</StyledSpan>
                    <StyledSpan>
                      grants permission to the Contest Parties to take visual
                      and audio recordings in connection with the Contest and
                      agrees to the publication, reproduction and/or other use
                      of his/her name, address, voice, statements about the
                      Contest and/or recordings, photograph or other likeness in
                      perpetuity without further notice or compensation, in any
                      publicity or advertisement carried out by the Sponsor in
                      any manner whatsoever, including print, broadcast or the
                      Internet. &nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan>5. GENERAL CONDITIONS:&nbsp;</StyledSpan>
                    </strong>
                  </p>
                  <p>
                    <StyledSpan>
                      By participating in this Contest, each entrant: (i) agrees
                      to be bound by these Contest Rules; (ii) agrees to release
                      and hold harmless the Released Parties from and against
                      any and all claims based on publicity rights, defamation,
                      invasion of privacy, copyright infringement, trademark
                      infringement or any other intellectual property–related
                      cause of action; and (iii) releases the Released Parties
                      from any and all liability in connection with this Contest
                      and their participation therein.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <strong>
                      <StyledSpan
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Entrant and Prize Winner Privacy
                      </StyledSpan>
                    </strong>
                    <StyledSpan>
                      : Your submission of an entry in the Contest constitutes
                      your consent to participate in this Contest and your
                      consent for Sponsor, its vendors and the Administrator to
                      obtain, use, and transfer your name, telephone contact
                      information and other entry information for the purposes
                      of administering this Contest and awarding the prizes, to
                      comply with legal requirements and in accordance with the
                      Sponsor’s Privacy Policy, accessible at&nbsp;
                    </StyledSpan>
                    <StyledSpan>
                      https://www.westjet.com/en-ca/legal/privacy-policy
                    </StyledSpan>
                    <StyledSpan>
                      . Without limiting the generality of the foregoing, but
                      for greater certainty, eligible entrants who are entitled
                      to receive a Loyalty Prize and who provide to the Sponsor
                      (through the Sponsor’s Representative or otherwise)
                      additional personal information (which may include, but is
                      not limited to their email address, WestJet Rewards
                      account number and other personal information) in
                      connection with the creation of, or crediting of the
                      Loyalty Prize to, a WestJet Rewards account are consenting
                      to the use of such information for purposes of Contest
                      administration and the administration and use of such
                      WestJet Rewards account by WestJet.&nbsp;
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      The Sponsor reserves the right, in its sole and absolute
                      discretion, to withdraw, suspend or amend this Contest in
                      any way, in the event of an error, technical problem,
                      computer virus, bug, tampering, unauthorized intervention,
                      fraud, technical failure or any other cause beyond the
                      reasonable control of the Sponsor that interferes with the
                      proper conduct of this Contest as contemplated by these
                      Contest Rules. Any attempt to deliberately damage any
                      website or to undermine the legitimate operation of this
                      Contest is a violation of criminal and civil laws and
                      should such an attempt be made the Sponsor reserves the
                      right to seek remedies and damages to the fullest extent
                      permitted by law. The Sponsor reserves the right, in its
                      sole and absolute discretion to cancel, amend, or suspend
                      this Contest, or to amend these Contest Rules, without
                      prior notice or obligation, in the event of any accident,
                      administrative, human processing error, other error of any
                      kind, or for any other reason.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      This Contest is governed by the laws of Alberta and the
                      laws of Canada applicable therein, without giving effect
                      to any choice of law or conflict of law rules. The
                      decisions of the Sponsor with respect to all aspects of
                      this Contest are final and binding on all entrants without
                      right of appeal, including, without limitation, any
                      decisions regarding the eligibility/disqualification of
                      entrants and/or entries. Except where prohibited by law,
                      entrant agrees that any and all disputes, claims and
                      causes of action arising out of, or connected with, the
                      Contest or any prize awarded shall be resolved
                      individually, without resort to any form of class action,
                      and exclusively by the appropriate court located in the
                      Province of Alberta.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      The Sponsor reserves the right, in its sole and absolute
                      discretion and without prior notice, to adjust any of the
                      dates and/or timeframes stipulated in these Contest Rules,
                      to the extent necessary, for purposes of verifying
                      compliance by any entrant or entry with these Contest
                      Rules, or as a result of technical problems, or in light
                      of any other circumstances which, in the opinion of the
                      Sponsor, in its sole and absolute discretion, affect the
                      proper administration of the Contest as contemplated in
                      these Contest Rules.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      In the event of any discrepancy or inconsistency between
                      the terms and conditions of these Contest Rules and
                      disclosures or other statements contained in any
                      Contest-related materials, including, but not limited to
                      website, print and/or online advertising, if any, the
                      terms, and conditions of these Contest Rules shall
                      prevail, govern, and control. In the event that any
                      provision of the Contest Rules is determined to be invalid
                      or otherwise unenforceable or illegal, the other
                      provisions will remain in effect and will be construed in
                      accordance with their terms as if the invalid or illegal
                      provision were not contained herein. Entrants agree to
                      waive any rights to claim ambiguity of these Contest
                      Rules.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      WestJet ® is a registered trademark of WestJet Airlines
                      Ltd.
                    </StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>&nbsp;</StyledSpan>
                  </p>
                  <p>
                    <StyledSpan>
                      ® Registered trademarks of Royal Bank of Canada. RBC
                    </StyledSpan>
                    <StyledSpan>&nbsp;and&nbsp;</StyledSpan>
                    <StyledSpan>
                      Royal Bank are registered trademarks of Royal Bank of
                      Canada.
                    </StyledSpan>
                    <StyledSpan
                      style={{
                        fontFamily: '"Noto Sans"',
                        color: "rgb(102,102,102)",
                        fontSize: 14,
                        background: "rgb(255,255,255)",
                      }}
                    >
                      <br />
                    </StyledSpan>
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
